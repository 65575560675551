@import '../../../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  margin: auto;
  padding: 20px 10px 20px 20px;

  @media (--viewportMedium) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 36px 36px 22px;
  }
}

.hiddenStep {
  visibility: hidden;
}
